import { css } from 'styled-components';

export const sizes = {
  big: 1400,
  medium: 959,
  small: 768,
  xsmall: 667,
};

export default Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${sizes[label]}px) {
      ${css(...args)};
    }
  `;
  return acc;
}, {});
